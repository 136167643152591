<template>
  <d-edit-card ref="editCard" card-title="Account" prev-page="AccountsList">
    <q-card-section v-if="account">
      <div class="row q-col-gutter-md">
        <section class="col-4">
          <div class="flex items-center q-mb-sm">
            <span class="text-subtitle1 q-mr-sm text-primary">
              {{ account.name }}
            </span>

            <q-btn
              v-if="accessUpdateAccountName"
              round
              flat
              size="xs"
              icon="mdi-pencil"
              class="flex-shrink-0"
              @click="toggleUpdateAccountForm(true)"
            />
          </div>

          <p class="text-subtitle2 text-weight-bold q-mb-xs">
            Select Method: {{ account.methodType.name }}
          </p>

          <p class="text-subtitle2 text-weight-bold q-mb-none">
            Select Agent: {{ account.agent.username }}
          </p>

          <DSwitch
            v-model="account.enable"
            :disable="!accessEnableAccount && !accessDisableAccount"
            :disable-true-value="!accessDisableAccount"
            :disable-false-value="!accessEnableAccount"
            dense
            left-label
            color="green"
            label="Enable"
            class="q-mt-md"
            @input="toggleAccountEnable"
          />

          <q-btn
            v-if="accessAddRequisites"
            :disable="!canAddRequisites"
            unelevated
            icon="mdi-plus"
            color="primary"
            class="flex q-mt-lg text-weight-bold"
            @click="toggleRequisiteForm(true)"
          >
            Add requisites
          </q-btn>
        </section>

        <section class="col-8">
          <CredentialsForm
            :account-id="account.id"
            :credentials.sync="account.credentials"
          />
        </section>

        <section class="col-12">
          <p class="text-subtitle2 q-mb-xs q-mt-lg text-red text-weight-bold">
            Response Account
          </p>

          <q-card v-if="accountResponse">
            <q-card-section class="text-subtitle1">
              <span v-html="accountResponse" />
            </q-card-section>
          </q-card>
        </section>

        <section class="col-12 q-mt-md">
          <RequisitesTable
            :account-id="account.id"
            :requisites="account.requisites"
            @deleted:requisite="onDeletedRequisite"
            @updated:requisite="onUpdatedRequisite"
            @created:requisite="onCreatedRequisite"
          />
        </section>
      </div>
    </q-card-section>

    <q-dialog
      v-if="isShowRequisiteForm"
      v-model="isShowRequisiteForm"
      persistent
    >
      <RequisiteForm
        :account-id="account.id"
        @updated="onUpdatedRequisite"
        @created="onCreatedRequisite"
        @cancel="toggleRequisiteForm(false)"
      />
    </q-dialog>

    <q-dialog
      v-if="isShowUpdateAccountForm"
      v-model="isShowUpdateAccountForm"
      persistent
    >
      <UpdateAccountForm
        :account-id="account.id"
        :account-name.sync="account.name"
        @updated="toggleUpdateAccountForm(false)"
        @cancel="toggleUpdateAccountForm(false)"
      />
    </q-dialog>
  </d-edit-card>
</template>

<script>
import { DSwitch } from '@/features/switch';
import DEditCard from '@/layouts/edit-card';
import { accountController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  name: 'AccountEdit',
  components: {
    DEditCard,
    DSwitch,
    RequisiteForm: () => import('./components/requisite-form'),
    UpdateAccountForm: () => import('./components/update-account-form'),
    CredentialsForm: () => import('./components/credentials-form'),
    RequisitesTable: () => import('./components/requisites-table'),
  },
  data() {
    return {
      loading: false,
      account: null,
      isShowRequisiteForm: false,
      isShowUpdateAccountForm: false,
    };
  },
  computed: {
    canAddRequisites() {
      return !!this.account?.credentials?.length;
    },
    accessUpdateAccountName() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN]);
    },
    accessAddRequisites() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
    accessEnableAccount() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    accessDisableAccount() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
    accountResponse() {
      return this.account?.response?.replace(
        /\\n/gi,
        '<hr class="q-mt-sm"></hr>'
      );
    },
  },
  watch: {
    loading(value) {
      const editCardRef = this.$refs.editCard;

      if (!editCardRef) return;

      editCardRef.loading = value;
    },
  },
  methods: {
    async fetchAccount() {
      this.loading = true;

      const id = +this.$route.params.id;

      try {
        const data = await accountController.fetchRefillAccount({
          id,
        });

        this.loading = false;

        Object.freeze(data.requisites);

        this.account = data;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchAccountRequisites() {
      this.loading = true;

      try {
        const { requisites } = await accountController.fetchRefillAccount({
          id: this.account.id,
        });

        this.loading = false;
        this.account.requisites = Object.freeze(requisites);
      } catch (error) {
        this.loading = false;
      }
    },
    toggleRequisiteForm(showed = !this.isShowRequisiteForm) {
      this.isShowRequisiteForm = showed;
    },
    toggleUpdateAccountForm(showed = !this.isShowUpdateAccountForm) {
      this.isShowUpdateAccountForm = showed;
    },
    toggleAccountEnable(enable) {
      accountController.updateRefillAccountEnable({
        id: this.account.id,
        enable,
      });
    },
    onCreatedRequisite() {
      this.toggleRequisiteForm(false);
      this.fetchAccountRequisites();
    },
    onUpdatedRequisite() {
      this.toggleRequisiteForm(false);
      this.fetchAccountRequisites();
    },
    onDeletedRequisite(id) {
      console.log('onDeletedRequisite', id);
      this.account.requisites = Object.freeze(
        this.account.requisites.filter((requisite) => requisite.id !== id)
      );
    },
  },
  mounted() {
    this.fetchAccount();
  },
};
</script>
